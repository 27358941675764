@import "../../../scss/index.scss";

.card-ingreso {

  &__titulo {
    margin-bottom: 50px !important;
    font-size: 24px;
    font-weight: 300;
  }

  
  .card {
    border: none;
    border-radius: 25px;
    background-color: transparent;

    // background: rgba(0, 160, 175, 1);

    .card-body {
      .h5 {
        color: $bacgroung-grey;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  &__buttons {
    display: block !important;

    &-item {
      margin-bottom: 10px;

      &:hover {
        color: $background-green;
      }

      button {
        background-color: $background-blue;
      }

      span {
        margin-left: 10px;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
      }
    }

    svg {
      color:$font-ligth;
    }
  }
}
