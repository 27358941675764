@import "../../scss/index.scss";

.settings {
  margin: 50px 100px 0 100px;

  &__header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-bottom: 50px;

    > h1 {
      font-size: 32px;
    }

    svg{
      height: 24px;
      color: $danger;

      &:hover{
        cursor: pointer;
      }
    }
  }

  .avatar-name {
    display: flex;
    align-items: center;

    .user-avatar {
      display: table;
      margin: 0 !important;
      border: 2px solid #9a9a9a;
      border-style: dashed;
      border-radius: 100px;
      padding: 10px;
      margin-bottom: 20px;

      img {
        width: 100px;
        border-radius: 50px;
      }
    }

    .user-name {
      display: flex;
      justify-content: space-between;
      width: 100%;

      h2 {
        font-size: 16px;
        margin: 0 20px !important;
      }
    }
  }

  .user-password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    h3 {
      margin: 0;
      font-size: 18px;
    }

    button {
      background-color: $background-yellow;
    }
  }
  
}
