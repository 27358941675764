@import "../../scss/index.scss";

.home {
  overflow-y: scroll;
  height: calc(100vh - 105px);

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
    color: $font-black;
  }

  &__loading {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 100vh;

    .spinner-border {
      width: 70px;
      height: 70px;
      color: $background-blue;
    }
  }

  &__card {
    background-color: transparent !important;
    border: none !important;

    .h5 {
      text-align: center;
    }

    .accordion-item {
      background-color: transparent;
    }

    .accordion-button {
      background-color: $background-pink;
      color: $font-ligth;
      font-weight: bold;
      border-radius: 50px !important;
    }
  }

  .lista-materiales {
    padding-left: 0;
    list-style: none;

    li {
      margin-top: 10px;

      &:hover {
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        font-weight: bold;
        color: $background-green;
      }

      &:first-child {
        margin-top: 5px;
      }
    }
  }
}
