@import "../../../scss/index.scss";

.card-general {
  > h4 {
    font-size:26px;
    font-weight:300;
  }
  .card {
    border: none;
    border-radius:25px;
    background: rgba(0, 160, 175, 1);

    .card-body {
      .h5 {
        color: $bacgroung-grey;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;

    svg {
      &:hover {
        color: #fff;
      }
    }

    button {
      background-color: $background-yellow;
      margin-left: 5px !important;
      &:last-of-type{
        background-color: $background-green;
      }
    }
  }
}
