@import "../../../scss/index.scss";

.signOut-modal{
  .modal-content {
    background-color: $primary-color;
    border-radius: 12px;
    color: $font-ligth;
    text-align: center !important;
  
    .modal-header {
      border-bottom: none;
      color: $font-ligth !important;

      .h4{
        margin-left: 50px;
      }
    }
  
    .modal-footer {
      border-top: none;
    }
  }
}
