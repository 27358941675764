@import "../../../scss/index.scss";

.abecedaire {
  overflow-y: scroll;
  height: calc(100vh - 105px);

  &::-webkit-scrollbar {
    display: none;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 105px);

    .spinner-border {
      width: 70px;
      height: 70px;
      color: $background-blue;
    }
  }

  &__fila {
    margin-top: 50px;
  }

  &__titulo {
    margin-top: 25px;
    margin-bottom: 0 !important;
    font-size: 42px;
    font-weight: 300;
  }
}
