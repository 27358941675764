@import "../../scss/index.scss";

.auth {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &__box {
    position: relative;
    height: 500px;
    width: 500px;
    background-color: $font-ligth;
    border-radius: 5%;

    &-logo {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 20px;

      img {
        width: 250px;
      }
    }
  }
}
