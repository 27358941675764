// Colores del sistema
$primary-color: #002c50;
$background-yellow: #f3c300;
$background-blue: #00a0af;
$background-green: #76bc21;
$background-pink: #d50037;
$bacgroung-grey: #e8eaed;
$background-grey-ligth: #3c3c3c;

$font-ligth: #fff;
$font-black: #000;

$success: #198754;
$warning: #ffc107;
$danger: #dc3545;
