@import "../../../scss/index.scss";

.basic-modal {
  .modal-content {
    .modal-header {
      color: $font-black;
      border-bottom: none;
    }

    .modal-body {
      .icon-inputs {
        .input-group-text {
          background-color: transparent;
          border: none;
        }

        input {
          border: none;
          border-bottom: solid 1px $font-black;
          border-bottom-right-radius: 0;
        }
      }

      button {
        background-color: $primary-color;
        color: $font-ligth;
      }
    }

    .modal-footer {
      border-top: none;
    }
  }
}
