@import "../../scss/index.scss";

.topbar {
  background-color: $primary-color;
  padding: 15px 20px;
  width: 100%;

  .container-topbar {
    display: flex;
    justify-content: space-between !important;
    color: $font-ligth !important;

    &__logo {
      img {
        width: 210px;
      }
    }

    &__settings {
      display: flex;
      align-items: center;

      &-user {
        img {
          height: 30px;
          width: 30px;
          margin-right: 10px;
          border-radius: 50px;

          &:hover {
            cursor: pointer;
          }
        }

        a {
          text-decoration: none;
          color: $font-ligth;
          font-weight: 500;

          &:hover{
            color: $background-yellow;
          }
        }

        span {
          padding-left: 0 !important;
          padding-right: 10px;
        }
      }

      span {
        padding-left: 10px;

        svg {
          font-size: 17px;
          &:hover {
            cursor: pointer;
            color: $danger;
          }
        }
      }
    }
  }
}
