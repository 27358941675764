@import "./scss/index.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:$bacgroung-grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify {
  div {
    color: $font-ligth;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .Toastify__toast-container--top-center {
    width: 100%;
    top: 0;
    padding: 0px;
    margin: 0px;

    .Toastify__toast--success {
      background-color: $success;

      .Toastify__toast-icon {
        display: none;
      }

      .Toastify__close-button {
        align-self: center;
      }
    }

    .Toastify__toast--warning {
      background-color: $warning;
    }

    .Toastify__toast--error{
      background-color: $danger;
    }
  }
}





