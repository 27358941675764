@import "../../../scss/index.scss";

.logged {
  display: flex;
  justify-content: center;
  height: 50%;
  align-items: center;

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $font-black;
  }

  .input-group-text {
    background-color: transparent;
    border: none;
  }

  .password-restore {
    display: flex;
    justify-content: flex-end;

    span {
      font-size: 12px;

      &:hover {
        cursor: pointer;
        color: $background-yellow;
      }
    }
  }

  button {
    background: $primary-color;
    color: $font-ligth;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $background-yellow;
      border-radius: 20px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    &:hover,
    :focus,
    :active {
      color: $font-black;
    }

    &:hover:before,
    &:focus:before,
    &:active:before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  .error-text {
    color: $danger;
    font-size: 12px;
    padding-left: 40px;
  }
}
