@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Thin.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-ThinItalic.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-LightItalic.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-MediumItalic.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-SemiBoldItalic.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../fonts/Poppins/Poppins-BlackItalic.ttf);
  font-style: italic;
  font-weight: 900;
}
