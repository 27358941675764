@import '../../scss/index.scss';

.return-bar{
    margin-top:30px;
    margin-left: 20px;

    &__icon{
        svg{
            height:24px;
            color:$danger;

            &:hover{
                cursor: pointer;
            }
        }
    }
}