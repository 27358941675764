@import "../../../scss/index.scss";

.libros-card {

    &__nameNivel{
        &:first-of-type{
            margin-top:50px;
        }
        font-size: 15px;
        margin-top:15px;
        text-align:center;
    }

  .card {
    border: none;
    border-radius: 25px;
    background: rgba(0, 160, 175, 1);

    .card-body {
      .h5 {
        color: $bacgroung-grey;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;

    svg {
      &:hover {
        color: #fff;
      }
    }

    button {
      background-color: $background-yellow;
    }
  }
}
